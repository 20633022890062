import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';
import { CallToActionKeys as MiniHubCallToActionKeys } from '@bgo-ui/common/components/Header/MiniHub/constants';
import { getLoyalityTier } from '@bgo-ui/common/components/Header/MiniHub/helpers';
import { shouldDisplayMonogramHelpLineText } from 'pdp/components/ProductPage/components/Checkout/checkoutOptionRenderer';
import { isZeroDollarProduct } from 'pdp/components/ProductPage/reducers/productReducer';

const booleanToString = value =>
  typeof value !== 'undefined' && String(Boolean(value));

export const productUtag = payload => {
  const isSwatchProduct = options => {
    const hasSwatch = (options.productOptions || []).some(
      productOption =>
        productOption.label === 'color' &&
        !isEmpty(productOption.values[0].url),
    );
    return booleanToString(hasSwatch);
  };
  const getAdornmentsType = payload => {
    return payload.price.adornments ? ['Sale'] : ['Regular'];
  };

  const getTotalSkuCount = payload => {
    return payload.skus.map(sku => sku.id).length;
  };

  const getAvailableSkuCount = payload => {
    return payload.skus
      .map(sku => booleanToString(sku.sellable))
      .filter(availableSku => availableSku === 'true').length;
  };

  return {
    product_type: ['non-group'],
    product_brand: payload.designer && payload.designer.name,
    product_id: [payload.id],
    product_name: [payload.name],
    product_cmos_catalog_id: [payload.metadata.cmosCatalogId],
    product_cmos_item: [payload.metadata.cmosItem],
    product_pim_style: [payload.metadata.pimStyle],
    product_price: [payload.price.retailPrice],
    product_pricing_adornment_flag: [booleanToString(payload.price.adornments)],
    product_swatch: isSwatchProduct(payload.options || { productOptions: [] }),
    product_cmos_sku: payload.skus.map(sku => sku.metadata.cmosSkuId),
    product_expected_availability: payload.skus.map(
      sku => sku.expectedShipDays || '',
    ),
    product_sellable_sku: payload.skus.map(sku =>
      booleanToString(sku.sellable),
    ),
    product_sale_type: payload.details.clearanceItem
      ? ['Clearance']
      : getAdornmentsType(payload),
    total_sku_count: getTotalSkuCount(payload),
    available_sku_count: getAvailableSkuCount(payload),
  };
};

export const quickLookProductUtagDataKeys = [
  'page_name',
  'event_name',
  'product_available',
  'parent_cmos_item_code',
  'product_showable',
  'product_monogrammable',
  'unsellable_skus',
  'product_configurable',
  'product_inventory_status',
  'product_type',
  'product_brand',
  'product_id',
  'product_name',
  'product_cmos_catalog_id',
  'product_cmos_item',
  'product_price',
  'product_pricing_adornment_flag',
  'product_swatch',
  'product_cmos_sku',
  'product_expected_availability',
  'product_sellable_sku',
  'product_suppress_checkout_flag',
  'product_sale_type',
  'product_dropship_flag',
  'product_nm_exclusive',
  'edw_pricing_adornment',
  'total_sku_count',
  'available_sku_count',
  'cart_total_items',
  'cart_change_product_cmos_item',
  'cart_change_product_quantity',
  'cart_change_product_cmos_sku',
  'cart_change_product_name',
  'cart_change_bops_flag',
  'cart_change_product_replenish_time',
  'cart_change_nmo_sku_id',
  'cart_change_product_price',
  'cart_change_product_id',
  'cart_change_product_catalog_id',
];
const isProductNotSellable = payload =>
  payload.details.suppressCheckout ||
  isZeroDollarProduct(payload) ||
  shouldDisplayMonogramHelpLineText(payload);

const getProductDropShip = skus => {
  return skus.some(sku => sku.dropShip);
};

const getEDWPricingType = payload => {
  if (
    !isEmpty(payload.promotions) &&
    payload.price.promotionalPrice &&
    payload.price.adornments
  )
    return 'CP';
  if (payload.price.adornments) return 'C';
  if (payload.promotions && payload.price.promotionalPrice) return 'RP';
  return 'R';
};

const inventoryStatus = {
  inStock: 'In Stock',
  preOrder: 'Pre Order',
  backOrder: 'Back Order',
  OOS: 'OOS',
};
export const quickLookProductUtagData = payload => ({
  ...productUtag(payload),
  page_name: 'product detail',
  event_name: 'Quickview',
  product_type: ['non-group'],
  product_available: [payload.displayable.toString()],
  parent_cmos_item_code: '',
  product_showable: [
    (payload.displayable && isProductNotSellable(payload)).toString(),
  ],
  product_monogrammable: [payload.isCustomizable.toString()],
  unsellable_skus: payload.skus.some(sku => !sku.sellable).toString(),
  product_configurable: ['false'],
  product_inventory_status: payload.skus.map(
    sku =>
      find(inventoryStatus, (statusValue, statusKey) => sku[statusKey]) ||
      inventoryStatus['OOS'],
  ),
  product_dropship_flag: [
    booleanToString(getProductDropShip(payload.skus) || false),
  ],
  product_nm_exclusive: [booleanToString(payload.productFlags.isOnlyAtNM)],
  edw_pricing_adornment: [getEDWPricingType(payload)],
  product_review_count: '0',
  product_review_rating: 'false',
  product_suppress_checkout_flag:
    payload.details && toString(payload.details.suppressCheckout),
  product_brand: payload.designer && payload.designer.name,
});

export const favoriteUtagData = payload => {
  return {
    event_name: payload.eventId,
    item_type: payload.itemType,
    favorite_item_status: payload.favoriteItemStatus,
    product_cmos_catalog_id: [payload.cmosCatalogId],
    product_cmos_item: [payload.cmosItem],
    product_pim_style: [payload.pimStyle],
    product_favorited_item: [payload.favoriteItem],
    product_favorited_catalog: [payload.cmosCatalogId],
  };
};

export const productListUtag = payload => ({
  category_results: toString(payload.total),
  page_number: toString(payload.page),
  page_template: payload.templateType,
  sort_method: payload.sortBy,
  page_definition_id: payload.templateType,
  previous_view_sort_submit: toString(payload.previousViewSortSubmit),
  product_cmos_catalog_id: payload.cmosCatalog,
  product_cmos_item: payload.cmosItem,
  facet_eligible: toString(payload.facetEligible),
  plp_type: toString(payload.plpType),
  pcs_enabled: toString(payload.pcsEnabled),
});

export const searchListUtag = payload => ({
  page_definition_id: toString(payload.page_definition_id),
  page_name: toString(payload.page_name),
  page_type: toString(payload.page_type),
  page_version: toString(payload.page_version),
  search_type: toString(payload.search_type),
  internal_search_term: toString(payload.internal_search_term),
  category_results: toString(payload.total),
  page_number: toString(payload.page),
  sort_method: get(payload, 'sortBy', '').includes('flg_pre_order')
    ? 'PRE_ORDER'
    : payload.sortBy,
  previous_view_filter_submit: toString(payload.previousViewFilterSubmit),
  previous_view_sort_submit: toString(payload.previousViewSortSubmit),
  page_template: toString(payload.page_template),
  previous_view_search_submit: toString(payload.previousViewSearchSubmit),
  product_cmos_catalog_id: map(payload.productsMetadata, 'cmosCatalogId'),
  product_cmos_item: map(payload.productsMetadata, 'cmosItem'),
  product_pim_style: map(payload.productsMetadata, 'pimStyle'),
  search_type_ahead: toString(payload.search_type_ahead),
});

export const favoriteProductListUtag = payload => ({
  page_definition_id: toString(payload.page_definition_id),
  page_name: toString(payload.page_name),
  page_type: toString(payload.page_type),
  page_version: toString(payload.page_version),
  category_results: toString(payload.total),
  page_number: toString(payload.page),
  previous_view_sort_submit: toString(payload.previousViewSortSubmit),
  sort_method: payload.sortBy,
  filter_selection: payload.filterOptions.filterSelection,
  filter_type: payload.filterOptions.filterType,
  previous_view_filter_submit: toString(payload.previousViewFilterSubmit),
});

export const favoritedesignersListUtag = payload => ({
  page_definition_id: toString(payload.page_definition_id),
  page_name: toString(payload.page_name),
  page_type: toString(payload.page_type),
});

export const categoryTemplatesUtag = payload => ({
  page_template: toString(payload.page_template),
  page_definition_id: toString(payload.page_definition_id),
  page_type: toString(payload.page_type),
  page_name: toString(payload.page_name),
});

export const ctpFavoriteDesignerUtag = payload => ({
  event_name: toString(payload.event_name),
  item_type: toString(payload.item_type),
  favorite_item_status: toString(payload.favorite_item_status),
  favorite_designer: toString(payload.favorite_designer),
});

export const selectedFilterUtag = payload => {
  return {
    filter_selection: payload.filterOptions.filterSelection,
    filter_type: payload.filterOptions.filterType,
  };
};

export const selectedFacetUtag = payload => {
  return {
    facet_selection: payload.facetOptions.filterSelection,
    facet_type: payload.facetOptions.filterType,
  };
};

export const recentSizesUtag = payload => {
  return {
    elevated_recent_sizes: payload.recentSizes,
  };
};

export const promoTileFlagUtag = payload => {
  return {
    promo_tile_flag: payload.promoTileFlag,
  };
};

export const facetFlagUtag = payload => {
  return payload.facetFlag
    ? {
        facet_flag: toString(payload.facetFlag),
        previous_view_facet_submit: toString(payload.previousViewFacetSubmit),
      }
    : {
        previous_view_filter_submit: toString(payload.previousViewFilterSubmit),
      };
};
// Commenting all TomFord related analytics implementation for BG Phase-II
/* export const tomFordSizeUtag = payload => {
  return {
    page_definition_id: 'Product Configurator',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'Tom Ford Product Configurator',
    page_template: 'Product Configurator',
    page_type: 'Product Configurator',
    product_config_interaction: `${payload}mm`,
    event_name: 'configstep1',
  };
};

export const tomFordCaseUtag = payload => {
  return {
    page_definition_id: 'Product Configurator',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'Tom Ford Product Configurator',
    page_template: 'Product Configurator',
    page_type: 'Product Configurator',
    product_config_interaction: 'select case material',
    product_config: `${payload.cmosItem}|${payload.color}`,
  };
};

export const tomFordStrapColorUtag = payload => {
  return {
    page_definition_id: 'Product Configurator',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'Tom Ford Product Configurator',
    page_template: 'Product Configurator',
    page_type: 'Product Configurator',
    product_config_interaction: 'select strap',
    product_config: `${payload.cmosItem}|${payload.color}`,
  };
};


export const tomFordTabUtag = payload => {
  return {
    ...payload,
    page_definition_id: 'Product Configurator',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'Tom Ford Product Configurator',
    page_template: 'Product Configurator',
    page_type: 'Product Configurator',
  };
};

export const tomFordBuckleUtag = payload => {
  const isSwatchProduct = (options) => {
    const hasSwatch = (options.productOptions || [])
      .some(productOption => productOption.label === 'color' && !isEmpty(productOption.values[0].url));
    return booleanToString(hasSwatch);
  };

  const getAdornmentsType = product => {
    return get(product, 'price.adornments', false) ? 'Sale' : 'Regular';
  };

  return {
    product_type: ['non-group'],
    page_definition_id: 'product',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'product detail',
    page_template: 'product detail',
    page_type: 'product detail',
    product_brand: payload.map(product => get(product, 'designer.name', '')),
    product_id: payload.map(product => product.id),
    product_name: payload.map(product => product.name),
    product_cmos_catalog_id: payload.map(product => get(product, 'metadata.cmosCatalogId', '')),
    product_cmos_item: payload.map(product => get(product, 'metadata.cmosItem', '')),
    product_pim_style: payload.map(product => get(product, 'metadata.pimStyle', '')),
    product_price: payload.map(product => get(product, 'price.retailPrice', '')),
    product_pricing_adornment_flag: payload.map(product => booleanToString(get(product, 'price.adornments', false))),
    product_swatch:
      payload.map(product => isSwatchProduct(product.options || { productOptions: [] })),
    product_cmos_sku: payload.map(product => get(product, 'skus[0].metadata.cmosSkuId', '')),
    product_expected_availability: payload.map(product => get(product, 'skus[0].expectedShipDays', '')),
    product_sellable_sku: payload.map(product => booleanToString(get(product, 'skus[0].sellable', false))),
    product_sale_type: payload.map(product => (get(product, 'details.clearanceItem', false) ? 'Clearance' : getAdornmentsType(product))),
    product_config_interaction: 'select buckle',
    product_config: payload.map(product => `${get(product, 'metadata.cmosItem', '')}|${get(product, 'skus[0].color.name', '')}`),
    event_name: 'pageLoad',
  };
}; */

export const addToBagDataKeys = [
  'event_name',
  'request_type',
  'ajax_response_id',
  'cart_change_product_cmos_item',
  'cart_change_product_quantity',
  'cart_change_product_cmos_sku',
  'cart_change_product_name',
  'cart_change_bops_flag',
  'cart_change_product_replenish_time',
  'cart_change_nmo_sku_id',
  'cart_change_product_price',
  'cart_change_product_id',
  'cart_change_product_catalog_id',
  'available_sku_count',
  'total_sku_count',
];

export const addToBagDataKeysWishlist = [...addToBagDataKeys];

export const addToBagUtag = ({ productIds = [], utagData }) => {
  const skus = utagData.nmo_sku_id || [];
  const data = {
    event_name: utagData.ajax_response_id,
    request_type: utagData.request_type,
    ajax_response_id: utagData.ajax_response_id,
    cart_total_items: utagData.cart_total_items,
    cart_change_product_cmos_item: utagData.product_cmos_item,
    cart_change_product_quantity: utagData.cart_change_product_quantity,
    cart_change_product_cmos_sku: utagData.product_cmos_sku,
    cart_change_product_name: utagData.product_name,
    cart_change_bops_flag: utagData.cart_change_bops_flag,
    cart_change_product_replenish_time: utagData.cart_product_replenish_time,
    cart_change_nmo_sku_id: skus.slice(-1),
    cart_change_product_price: utagData.cart_change_product_price,
    cart_change_product_id: productIds.slice(-1),
    cart_change_product_catalog_id: utagData.cart_change_product_catalog_id,
    buy_now: utagData.buy_now,
  };

  return data;
};
// Commenting all TomFord related analytics implementation for BG Phase-II
/* export const tomFordTransientKeys = [
  ...addToBagDataKeys,
  'product_brand',
  'product_id',
  'product_name',
  'product_cmos_catalog_id',
  'product_cmos_item',
  'product_price',
  'product_pricing_adornment_flag',
  'product_swatch',
  'product_cmos_sku',
  'product_expected_availability',
  'product_sellable_sku',
  'product_sale_type',
  'product_type',
  'cart_total_items',
  'cart_change_nmo_sku_id',
  'cart_change_product_id',
];

export const tomFordStartOverResetKeys = [
  ...tomFordTransientKeys,
  'product_config',
  'product_config_interaction',
]; */

export const confAddToBagUtag = ({ productIds = [], utagData }) => {
  const data = {
    ...addToBagUtag({ productIds, utagData }),
    page_definition_id: 'product',
    bread_crumb: ['Tom Ford Product Configurator'],
    page_name: 'product detail',
    page_template: 'product detail',
    page_type: 'product detail',
    cart_change_nmo_sku_id: get(utagData, 'nmo_sku_id', []).slice(),
    cart_change_product_id: productIds.slice(),
    product_config_interaction: utagData.product_config_interaction,
  };
  return data;
};

export const shopTheLookAltProductClickUtag = () => {
  return {
    event_name: 'ShoptheLook',
    site_abbreviation: 'bg',
    site_environment: 'prod',
    sort_method: 'BEST_MATCH',
    video_on_page: 'false',
    reimagine_eligible: 'true',
    stl_alt_prod: 'true',
  };
};

export const getSTSUtagonModalLoad = () => {
  return {
    sts_modal_interaction: ['modal load'],
  };
};

export const getSTSUtagOnSeeMore = () => {
  return {
    product_finding_method: ['Shop the Style'],
    sts_modal_interaction: ['see more'],
  };
};

/**
 *
 * Data for old or new login page layout based on payload
 * @param {Object} payload Payload data
 * @param {boolean} payload.isNewLayout True for new layout, false for old layout
 *
 * @returns {Object} data Partial utag data object
 */
export const loginPageTemplateData = payload => {
  return payload && payload.isNewLayout
    ? {
        page_definition_id: 'account',
        page_name: 'Login',
        page_template: 'Profile',
        page_type: 'Account',
      }
    : {
        page_definition_id: 'account',
        page_name: 'login',
        page_template: 'account',
        page_type: 'account',
      };
};

/**
 *
 * Data for old or new registration page layout based on payload
 * @param {Object} payload Payload data
 * @param {boolean} payload.isNewLayout true for new layout, false for old layout
 *
 * @returns {Object} data Partial utag data object
 */
export const registrationPageTemplateData = payload => {
  return payload && payload.isNewLayout
    ? {
        page_definition_id: 'account',
        page_name: 'Register',
        page_template: 'Profile',
        page_type: 'Account',
      }
    : {
        page_definition_id: 'registration',
        page_name: 'register',
        page_template: 'account',
        page_type: 'account',
      };
};

/**
 *
 * Data for old or new reset password page layout based on payload
 * @param {Object} payload Payload data
 * @param {boolean} payload.isNewLayout true for new layout, false for old layout
 *
 * @returns {Object} data Partial utag data object
 */
export const resetPasswordPageTemplateData = payload => {
  return payload && payload.isNewLayout
    ? {
        page_definition_id: 'account',
        page_name: 'Password Reset Confirmation',
        page_template: 'Profile',
        page_type: 'Account',
      }
    : {
        page_definition_id: 'reset_pw_complete',
        page_name: 'password reset confirmation',
        page_template: 'account',
        page_type: 'account',
      };
};

let loginReferrerTemplateFirstEvent = true;
/**
 * First time when this function is called, it will check if there was a redirect from /e/login page;
 * All the subsequent calls will return 'false', because those calls mean that React routing has happened,
 * although referrer value could still be /e/login, we reset the values of previous login to false
 * @returns {{login_bt_click: (string), logged_in_previous_page_flag: (string)}}
 */
export const loginReferrerTemplateData = () => {
  window.console.log('first event: ', loginReferrerTemplateFirstEvent);
  const value =
    loginReferrerTemplateFirstEvent &&
    document.referrer.includes('/e/login') &&
    window.sessionStorage.getItem('login_btn_clicked') === 'true'
      ? 'true'
      : 'false';
  loginReferrerTemplateFirstEvent = false;

  return {
    logged_in_previous_page_flag: value,
    login_bt_click: value,
  };
};

let registrationReferrerTemplateFirstEvent = true;
/**
 * First time when this function is called, it will check if there was a redirect from /e/register page;
 * All the subsequent calls will return 'false', because those calls mean that React routing has happened,
 * although referrer value could still be /e/register, we reset the values of previous register to false
 * @returns {{account_registration: (string)}}
 */
export const registrationReferrerTemplateData = () => {
  const value =
    registrationReferrerTemplateFirstEvent &&
    document.referrer.includes('/e/register')
      ? 'true'
      : 'false';
  registrationReferrerTemplateFirstEvent = false;

  return {
    account_registration: value,
  };
};

let miniHubOpenProfilePanelFirstEvent = true;

export const miniHubOpenProfilePanelData = () => {
  const value = miniHubOpenProfilePanelFirstEvent ? 'open panel' : undefined;
  miniHubOpenProfilePanelFirstEvent = false;

  return {
    profile_panel_interaction: value,
  };
};

const miniHubCtaReferrerConfig = {
  [MiniHubCallToActionKeys.INCIRCLE_DETAILS]: {
    firstEvent: true,
    value: 'bg credit card',
  },
  [MiniHubCallToActionKeys.ACCOUNT_DETAILS]: {
    firstEvent: true,
    value: 'overview',
  },
  [MiniHubCallToActionKeys.MY_ORDERS]: {
    firstEvent: true,
    value: 'order history',
  },
};

export const miniHubCtaReferrerData = key => {
  const value =
    miniHubCtaReferrerConfig[key].firstEvent &&
    window.sessionStorage.getItem('minihub_cta_referrer') === key
      ? miniHubCtaReferrerConfig[key].value
      : undefined;
  miniHubCtaReferrerConfig[key].firstEvent = false;
  window.sessionStorage.removeItem('minihub_cta_referrer');

  return {
    profile_panel_interaction: value,
  };
};

let loginReferrerLoyaltyTemplateFirstEvent = true;

export const loginReferrerLoyaltyTemplateData = loyaltyUser => {
  const value =
    loginReferrerLoyaltyTemplateFirstEvent &&
    document.referrer.includes('/e/login') &&
    loyaltyUser.isChecked;
  loginReferrerLoyaltyTemplateFirstEvent = false;

  if (value) {
    return loyaltyUser.status
      ? {
          loyalty_member_status: 'true',
          loyalty_member_type: 'plcc',
          loyalty_tier: getLoyalityTier(loyaltyUser.data.tierCode, true),
        }
      : {
          loyalty_member_status: 'false',
          loyalty_member_type: 'none',
          loyalty_tier: 'none',
        };
  }

  return {
    loyalty_member_status: undefined,
    loyalty_member_type: undefined,
    loyalty_tier: undefined,
  };
};

export const updatePlpGrsUtagData = data => {
  try {
    const {
      grsToken: attribution_token,
      grsFilter: facet_grs,
      grsOrderBy: sort_method_grs,
      products,
    } = data;
    const productPrice = map(products, product => {
      const price = (product.pprc || product.rprc || product.oprc)?.replace(
        /[^0-9.]+/g,
        '',
      );
      return price && Number(price).toFixed(2);
    });
    const originalProductPrice = map(products, product => {
      const price = (product.oprc || product.rprc || product.pprc)?.replace(
        /[^0-9.]+/g,
        '',
      );
      return price && Number(price).toFixed(2);
    });
    return {
      attribution_token,
      facet_grs,
      sort_method_grs,
      product_price: productPrice,
      product_price_original: originalProductPrice,
    };
  } catch (error) {
    return {};
  }
};
