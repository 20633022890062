export const LOADING_ADDRESS = 'LOADING_ADDRESS';
export const RESOLVED_ADDRESS = 'RESOLVED_ADDRESS';
export const REJECTED_ADDRESS = 'REJECTED_ADDRESS';
export const LOADING_PAYMENT = 'LOADING_PAYMENT';
export const RESOLVED_PAYMENT = 'RESOLVED_PAYMENT';
export const REJECTED_PAYMENT = 'REJECTED_PAYMENT';
export const LOADING_ORDERS = 'LOADING_ORDERS';
export const RESOLVED_ORDERS = 'RESOLVED_ORDERS';
export const REJECTED_ORDERS = 'REJECTED_ORDERS';
export const LOADING_CONTENT = 'LOADING_CONTENT';
export const RESOLVED_CONTENT = 'RESOLVED_CONTENT';
export const REJECTED_CONTENT = 'REJECTED_CONTENT';
export const LOADING_BG_CARD = 'LOADING_BG_CARD';
export const RESOLVED_BG_CARD = 'RESOLVED_BG_CARD';
export const REJECTED_BG_CARD = 'REJECTED_BG_CARD';
export const LOADING_USER_INFO = 'LOADING_USER_INFO';
export const RESOLVED_USER_INFO = 'RESOLVED_USER_INFO';
export const REJECTED_USER_INFO = 'REJECTED_USER_INFO';
export const LOADING_UPDATE_ADDRESS = 'LOADING_UPDATE_ADDRESS';
export const RESOLVED_UPDATE_ADDRESS = 'RESOLVED_UPDATE_ADDRESS';
export const REJECTED_UPDATE_ADDRESS = 'REJECTED_UPDATE_ADDRESS';
export const LOADING_ADD_ADDRESS = 'LOADING_ADD_ADDRESS';
export const RESOLVED_ADD_ADDRESS = 'RESOLVED_ADD_ADDRESS';
export const REJECTED_ADD_ADDRESS = 'REJECTED_ADD_ADDRESS';
export const SET_ADDRESS_ID = 'SET_ADDRESS_ID';
export const RESOLVED_ITEM_SELECT = 'RESOLVED_ITEM_SELECT';
export const LOADING_UPDATE_BASIC_ATTRIBUTES =
  'LOADING_UPDATE_BASIC_ATTRIBUTES';
export const RESOLVED_UPDATE_BASIC_ATTRIBUTES =
  'RESOLVED_UPDATE_BASIC_ATTRIBUTES';
export const REJECTED_UPDATE_BASIC_ATTRIBUTES =
  'REJECTED_UPDATE_BASIC_ATTRIBUTES';
export const LOADING_SEND_VERIFICATION_CODE = 'LOADING_SEND_VERIFICATION_CODE';
export const RESOLVED_SEND_VERIFICATION_CODE =
  'RESOLVED_SEND_VERIFICATION_CODE';
export const REJECTED_SEND_VERIFICATION_CODE =
  'REJECTED_SEND_VERIFICATION_CODE';
export const LOADING_CHANGE_PASSWORD = 'LOADING_CHANGE_PASSWORD';
export const RESOLVED_CHANGE_PASSWORD = 'RESOLVED_CHANGE_PASSWORD';
export const REJECTED_CHANGE_PASSWORD = 'REJECTED_CHANGE_PASSWORD';
export const ACCOUNT_DETAILS_OPEN_EDIT = 'ACCOUNT_DETAILS_OPEN_EDIT';
export const ACCOUNT_DETAILS_CLOSE_EDIT = 'ACCOUNT_DETAILS_CLOSE_EDIT';
export const EDIT_SECTION_NAME = 'EDIT_SECTION_NAME';
export const EDIT_SECTION_EMAIL = 'EDIT_SECTION_EMAIL';
export const EDIT_SECTION_DOB = 'EDIT_SECTION_DOB';
export const EDIT_SECTION_PHONE = 'EDIT_SECTION_PHONE';
export const EDIT_SECTION_INTERESTED_IN = 'EDIT_SECTION_INTERESTED_IN';
export const EDIT_SECTION_PASSWORD = 'EDIT_SECTION_PASSWORD';
export const OPEN_MY_ACCOUNT_MOBILE_NAV = 'OPEN_MY_ACCOUNT_MOBILE_NAV';
export const CLOSE_MY_ACCOUNT_MOBILE_NAV = 'CLOSE_MY_ACCOUNT_MOBILE_NAV';
export const LOADING_REWARDS = 'LOADING_REWARDS';
export const REJECTED_REWARDS = 'REJECTED_REWARDS';
export const RESOLVED_BG_CREDIT_CARD_PAGE = 'RESOLVED_BG_CREDIT_CARD_PAGE';
export const RESOLVED_MY_ORDERS_PAGE = 'RESOLVED_MY_ORDERS_PAGE';
export const RESOLVED_ORDER_DETAILS_PAGE = 'RESOLVED_ORDER_DETAILS_PAGE';
export const RESOLVED_PAYMENT_DETAILS_PAGE = 'RESOLVED_PAYMENT_DETAILS_PAGE';
export const RESOLVED_REWARDS_PAGE = 'RESOLVED_REWARDS_PAGE';
export const RESOLVED_GIFTCARDS_PAGE = 'RESOLVED_GIFTCARDS_PAGE';
export const RESOLVED_ADDRESS_BOOK_PAGE = 'RESOLVED_ADDRESS_BOOK_PAGE';
export const RESOLVED_ACCOUNT_DETAILS_PAGE = 'RESOLVED_ACCOUNT_DETAILS_PAGE';
export const LOADING_ADDITIONAL_USER_INFO = 'LOADING_ADDITIONAL_USER_INFO';
export const RESOLVED_ADDITIONAL_USER_INFO = 'RESOLVED_ADDITIONAL_USER_INFO';
export const REJECTED_ADDITIONAL_USER_INFO = 'REJECTED_ADDITIONAL_USER_INFO';
export const LOADING_UPDATE_ADDITIONAL_USER_INFO =
  'LOADING_UPDATE_ADDITIONAL_USER_INFO';
export const RESOLVED_UPDATE_ADDITIONAL_USER_INFO =
  'RESOLVED_UPDATE_ADDITIONAL_USER_INFO';
export const REJECTED_UPDATE_ADDITIONAL_USER_INFO =
  'REJECTED_UPDATE_ADDITIONAL_USER_INFO';
export const RESOLVED_NEW_WISHLIST_PAGE = 'RESOLVED_NEW_WISHLIST_PAGE';
export const LOADING_ORDER_DETAILS = 'LOADING_ORDER_DETAILS';
export const REJECTED_ORDER_DETAILS = 'REJECTED_ORDER_DETAILS';
export const RESOLVED_ORDER_DETAILS = 'RESOLVED_ORDER_DETAILS';
export const RESOLVED_INCIRCLE_CONCIERGE_PAGE =
  'RESOLVED_INCIRCLE_CONCIERGE_PAGE';
export const RESOLVED_INCIRCLE_LOYALTY_PAGE = 'RESOLVED_INCIRCLE_LOYALTY_PAGE';

export const INVALID_TOTP_MSG = 'Invalid or expired TOTP code provided.';
export const EMAIL_ALREADY_EXISTS_MSG =
  'An account with the given email already exists.';
export const CTA_LINK_FOR_MOBILE = '•••';
export const CTA_MODAL_TYPE = 'AddressCTAModal';
export const CTA_MODAL_STYLE = 'address-cta-modal';
export const DAB_MODAL_TYPE = 'AddressActionModal';
export const DAB_MODAL_STYLE = 'address-del-modal';
export const setDefaultButtonText = 'Set As Default';
export const saveButtonText = 'Save';
export const editButtonText = 'Edit';
export const cancelButtonText = 'Cancel';
export const updateButtonText = 'Update';
export const deleteButtonText = 'Delete';
export const removeButtonText = 'Remove';
export const genericError = 'Something went wrong, please try again';
export const verificationCodeDisplayError =
  'Verification code does not match or has expired. Please check your email and try again.';
export const ITEM_NOT_AVAILABLE = 'Item Not Available';
export const LOADING_CANCEL_ORDER = 'LOADING_CANCEL_ORDER';
export const RESOLVED_CANCEL_ORDER = 'RESOLVED_CANCEL_ORDER';
export const REJECTED_CANCEL_ORDER = 'REJECTED_CANCEL_ORDER';

export const LOADING_LOYALTY_SUMMARY = 'LOADING_LOYALTY_SUMMARY';
export const RESOLVED_LOYALTY_SUMMARY = 'RESOLVED_LOYALTY_SUMMARY';
export const REJECTED_LOYALTY_SUMMARY = 'REJECTED_LOYALTY_SUMMARY';

export const LOADING_LOYALTY_DOC = 'LOADING_LOYALTY_DOC';
export const RESOLVED_LOYALTY_DOC = 'RESOLVED_LOYALTY_DOC';
export const REJECTED_LOYALTY_DOC = 'REJECTED_LOYALTY_DOC';

export const LOADING_ACTIVATE_DOC = 'LOADING_ACTIVATE_DOC';
export const RESOLVED_ACTIVATE_DOC = 'RESOLVED_ACTIVATE_DOC';
export const REJECTED_ACTIVATE_DOC = 'REJECTED_ACTIVATE_DOC';

export const datesForSelect = {
  January: 31,
  February: 29,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31,
};
