import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import {
  PAGE_ID_ACCOUNT_DETAILS,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import Spinner from 'shared/components/Spinner/simpleSpinner';
import { useConstructor } from '../../../../utilities/hooks';
import MyAccountMobileBackHeader from '../MyAccountPage/MyAccountBackHeader/MyAccountMobileBackHeader';
import { getAccountDetailsForMyAccount } from './actions';
import { getContentForMyAccount, setResolvedPage } from '../../actions';
import { RESOLVED_ACCOUNT_DETAILS_PAGE } from '../../constants';
import NameField from './NameField/NameField';
import DateOfBirthField from './DateOfBirthField/DateOfBirthField';
import PhoneNumberField from './PhoneNumberField/PhoneNumberField';
import InterestedInField from './InterestedInField/InterestedInField';
import EmailField from './EmailField/EmailField';
import PasswordField from './PasswordField/PasswordField';
import BGNewsletterSection from './BGNewsletterSection/BGNewsletterSection';
import './AccountDetailsPage.scss';

const PAGE_NAME = 'Account details';

/**
 * @function AccountDetailsPage
 * @param {Function} props.getContentForMyAccount
 * @param {Function} props.getAccountDetailsForMyAccount
 * @param {Function} props.setPageId
 * @param {Function} props.setResolvedPage
 * @returns {React.ReactElement}
 */
const AccountDetailsPage = ({
  getContentForMyAccount,
  getAccountDetailsForMyAccount,
  setPageId,
  setResolvedPage,
  bgoLoyaltyToggle,
  loyPhoneToggle,
  isCheckedLoyaltyUser,
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useConstructor(() => {
    setPageId(PAGE_ID_ACCOUNT_DETAILS);
  });

  useEffect(() => {
    if (bgoLoyaltyToggle && isCheckedLoyaltyUser) {
      setResolvedPage(RESOLVED_ACCOUNT_DETAILS_PAGE);
    } else if (!bgoLoyaltyToggle) {
      setResolvedPage(RESOLVED_ACCOUNT_DETAILS_PAGE);
    }
  }, [bgoLoyaltyToggle, isCheckedLoyaltyUser]);

  useEffect(() => {
    Promise.all([
      getContentForMyAccount(),
      getAccountDetailsForMyAccount(),
    ]).then(() => {
      setIsDataLoaded(true);
    });
  }, []);

  return isDataLoaded ? (
    <div className="account-details-page">
      <MyAccountMobileBackHeader title={PAGE_NAME} />
      <div className="account-details-page__main-content">
        <h1 className="account-details-page__main-header desktop-tablet-only">
          {PAGE_NAME}
        </h1>
        <section className="account-details-page__section">
          <h2 className="account-details-page__section-header">
            Personal info
          </h2>
          <NameField />
          <hr />
          <DateOfBirthField />
          <hr />
          {bgoLoyaltyToggle && loyPhoneToggle && (
            <>
              <PhoneNumberField />
              <hr />
            </>
          )}
          <InterestedInField />
        </section>
        <section className="account-details-page__section">
          <h2 className="account-details-page__section-header">Access info</h2>
          <EmailField isEditVisible />
          <hr />
          <PasswordField />
        </section>
        <BGNewsletterSection />
      </div>
    </div>
  ) : (
    <Spinner />
  );
};

AccountDetailsPage.propTypes = {
  getContentForMyAccount: func.isRequired,
  getAccountDetailsForMyAccount: func.isRequired,
  setPageId: func.isRequired,
  setResolvedPage: func.isRequired,
};

const mapStateToProps = state => ({
  myAccountContent: state?.myaccount?.myAccountContent || true,
  isLoaded: state?.myaccount?.myAccountDetails?.isLoaded || false,
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  loyPhoneToggle: state?.toggles?.LOY_PHONE_UPDATE || false,
  isCheckedLoyaltyUser: state.user.loyaltyUser.isChecked || false,
});

const mapDispatchToProps = {
  getContentForMyAccount,
  getAccountDetailsForMyAccount,
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDetailsPage);
