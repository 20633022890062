import { types as MiniHubTypes } from './miniHub-actions';

const initialState = {
  data: {
    tierCode: '',
    pointBalance: 0,
    totalPointsToNextReward: 0,
  },
  error: false,
  isResolved: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MiniHubTypes.RESOLVED_LOYALTY_SUMMARY:
      return {
        ...state,
        data: {
          tierCode: action.payload.tierCode,
          pointBalance: action.payload.pointBalance,
          totalPointsToNextReward: action.payload.totalPointsToNextReward,
        },
        error: false,
        isResolved: true,
      };
    case MiniHubTypes.REJECTED_LOYALTY_SUMMARY:
      return {
        ...state,
        data: initialState.data,
        error: true,
        isResolved: false,
      };
    default:
      return state;
  }
};
