import httpWithLogging from 'universal/http-client';
import { getIdTokenAndUserInfo } from '@bgo-ui/common/client/utilities/utilities-amplify';
import { isNewCartCheckoutEnabled } from 'client-utils/is-new-cart-checkout';

export const types = {
  RESOLVED_LOYALTY_SUMMARY: 'minihub/RESOLVED_LOYALTY_SUMMARY',
  REJECTED_LOYALTY_SUMMARY: 'minihub/REJECTED_LOYALTY_SUMMARY',
  PROFILE_PANEL_OPEN: 'minihub/PROFILE_PANEL_OPEN',
};

export const getLoyaltySummary = (
  loyaltyProfileId,
  successCallback,
  errorCallback,
) => {
  return (dispatch, getState) => {
    if (!loyaltyProfileId) {
      dispatch({ type: types.REJECTED_LOYALTY_SUMMARY });
      return errorCallback();
    }

    const state = getState();

    if (state.miniHub.isResolved) {
      dispatch({
        type: types.RESOLVED_LOYALTY_SUMMARY,
        payload: state.miniHub.data,
      });
      return successCallback(state.miniHub.data);
    }

    const disableATGToggle = state.toggles.DISABLE_ATG_CALLS;
    const requestApi = httpWithLogging(state);

    return getIdTokenAndUserInfo(
      state.amplifyConfig,
      isNewCartCheckoutEnabled(state),
      true,
      disableATGToggle,
    )
      .then(info => {
        const ucaId = info?.userInfo?.attributes?.sub || null;
        const idToken = info?.tokenInfo?.idToken || null;
        const headers = {
          ucaId,
          authorization: idToken,
        };
        const body = {
          ucaId,
          loyaltyProfileId,
        };
        const callString = `${NMConfig.API_LOYALTY_SUMMARY}`;
        return requestApi
          .post(callString, body, { headers })
          .then(response => {
            const necessaryData = {
              tierCode: response.data.tierCode,
              pointBalance: response.data.pointBalance,
              totalPointsToNextReward: response.data.totalPointsToNextReward,
            };
            dispatch({
              type: types.RESOLVED_LOYALTY_SUMMARY,
              payload: necessaryData,
            });
            return successCallback(necessaryData);
          })
          .catch(() => {
            dispatch({ type: types.REJECTED_LOYALTY_SUMMARY });
            return errorCallback();
          });
      })
      .catch(() => {
        dispatch({ type: types.REJECTED_LOYALTY_SUMMARY });
        return successCallback();
      });
  };
};

export const openProfilePanel = () => {
  return dispatch =>
    dispatch({
      type: types.PROFILE_PANEL_OPEN,
    });
};
